import React from 'react';

import UnderConstruction from './components/UnderConstruction'

const staticpages = {
    health: {
      title: "Health  Research  and  Drug  Discoveries",
      theme: "red",
      sections: [
        <p>
          Myera’s vision is to align community and individual health outcomes to food production systems
          to optimize food products to suit specific nutritional needs.
        </p>,
        <p>
          Our human health research centres around two themes – discovery and production. As leaders in
          optimized nutrition we’re focused on finding new ways to address critical health issues in
          our population including eye health and vision loss, Alzheimer’s, Type II diabetes and more.
        </p>,
        <p>
          At Myera, we’ve been working with leading edge researchers at the Richardson Centre for
          Functional Foods and Neutraceuticals in Winnipeg to study the health benefits of astaxanthin,
          a compound derived from algae.
        </p>,
        <img alt='algae' src='/assets/images/splash/algaeh.png' />,
        <p>
          In 2017, Myera scaled-up their research to merge their algae and fish production systems.
          The resulting closed-loop system uses wastewater from the fish rearing tanks to ‘feed’
          the algae essential nutrients. As it grows, the algae filters the wastewater by utilizing
          nutrients and carbon dioxide and clean, fresh water is returned to the fish tanks to start
          the cycle over again. This state-of-the-art system is intensively managed to ensure optimal
          growing conditions for both the algae and fish- maximizing output value while minimizing
          inputs.
        </p>,
        <img className='half' style={{marginLeft:'25%',marginRight:'25%'}} alt='health process' src='/assets/images/splash/health-process.png' />,
        <p>
          The outcomes of our research merge with our ultimate vision of aligning community and individual
          health outcomes with food production through nutritional mapping and customized nutrition plans.
          By relating genetics and diet with geographical locations our research helps us identify potential
          nutritional deficiencies and opportunities to improve community and individual health.
          We can then turn to our food systems research to optimize the nutritional aspects of dietary staples
          to address deficiencies identified.
        </p>
      ]
    },
    "food-systems": {
      title: "Innovative Food and Animal Feed Systems",
      theme: "green",
      sections: [
        <p>
          Myera has developed a proprietary food production system based on the concept of a multi-trophic
          ecosystem. In simple terms, the concept capitalizes on the inter-relationships between plants,
          animals and their environment by ‘feeding’ the by-product of one production system into another.
        </p>,
        <p>
          Whereas traditional aquaculture systems focused solely on raising fish for consumption, Myera’s
          system raises fish and uses the by-products (ex. fish waste) to:
        </p>,
        <ul>
          <li>‘Feed’ algae to create nutraceuticals and bio-pharmaceuticals (health supplements)</li>
          <li>Provide vital nutrients for actively growing plants and crops for human or livestock consumption</li>
          <li>Produce high quality functional foods tailored to meet the nutritional needs of the local community</li>
        </ul>,
        <p>
          Capitalizing on these relationships requires complex and precise design, operation and management
          of the production system. Myera works with industry leaders to develop, test and commercialize
          the technology and methods used in their system.
        </p>,
        <p>
          Although it’s built around basic ecological principles, the system is nothing basic.
          Myera’s “smart” management system uses the latest in electronic monitoring and control systems,
          machine learning and advanced data to optimize every aspect of production.
        </p>,
        <img className='half' alt="lettuce" src="/assets/images/splash/lettuce-landscape.png"/>,
        <img className='half' alt="fish" src="/assets/images/splash/fish-landscape.png"/>,
        <h2>Aquaculture</h2>,
        <p>
          Building on the three pillars of sustainability, Myera has developed a unique, scaleable
          production model that provides economic and social benefits in a low impact, integrated
          ecological system.
        </p>,
        <p>
          Fish form the backbone of Myera’s sustainable food production system. But we’re not just
          ‘raising fish’. Our production system is built on a continuous improvement model with an
          emphasis on intelligence and innovation. From ‘smart’ sensors that support advanced, selective
          breeding to novel tank design we’re elevating traditional aquaculture.
        </p>,
        <h2>Functional Foods</h2>,
        <p>
          You may be familiar with the phrase “you are what you eat”. At Myera, we believe that you are
          what your food eats. That’s why we’re focused on optimizing the nutritional value of every
          commodity produced in our system. Our functional foods are naturally ‘improved’ versions of
          dietary staples- providing added nutritional benefits to the consumers.
        </p>,
        <p>
          Through strategic partnerships with industry leaders in agricultural production, we’re developing
          intelligent and innovative approaches to farming.
        </p>,
        <img className='half' alt="dairy cow" src="/assets/images/splash/cow-landscape.png"/>,
        <img className='half' alt="barley" src="/assets/images/splash/barley-landscape.png"/>,
      ]
    },
    team: {
      title: "Our Team",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    departments: {
      title: "Departments",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    faq: {
      title: "FAQ",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    press: {
      title: "In the Press",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    "community-programs": {
      title: "Community Programs",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    "school-initiatives": {
      title: "School Initiatives",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    "health-learning": {
      title: "Health Learning",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    "careers": {
      title: "Careers",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    "research-facility": {
      title: "Research Facility",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    "campus": {
      title: "Campus",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    "partners": {
      title: "Partners",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    },
    "support": {
      title: "Support",
      theme: null,
      sections: [
        <UnderConstruction />
      ]
    }
}

export default staticpages
