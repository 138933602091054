import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import UnderConstruction from './UnderConstruction'

class Signin extends Component {

  render() {
    return (
      <div class="signin">
        <h1>Partner Sign-in</h1>
        <UnderConstruction />
        <form>
          <input type="text" name="email" placeholder="email address" />
          <input type="password" name="password" placeholder="password" />
          <input type="submit" value="Sign In" />
        </form>
      </div>
    );
  }
}

// Signin.propTypes = {
// }
//
// Signin.defaultProps = {
// }

export default Signin;
