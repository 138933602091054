import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NotFound extends Component {
  render() {
    return (
      <div className="not-found-404">
        <h1>404 Not Found</h1>
        <p>
          The page you requested does not exist. If this is an error, please let
          us know <Link to='/contact'>here</Link>
        </p>
      </div>
    );
  }
}

export default NotFound;
