import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Page extends Component {

  render() {
    return (
      <div className={`page-wrapper ${this.props.theme}`}>
        <h1>{this.props.title}</h1>
        {this.props.sections}
      </div>
    );
  }
}

Page.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
  sections: PropTypes.array
}

Page.defaultProps = {
  title: 'Default Title',
  theme: '',
  sections: []
}

export default Page;
