import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <h5>All content copyright &copy;2017. All Rights Reserved.</h5>
        <h3>More Info</h3>
        {/*
           <div class="language-selector">
          <select class="language" name="language">
            <option value="english" selected>English</option>
          </select>
        </div>
        */}
      </div>
    );
  }
}

export default Footer;
