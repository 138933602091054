import React, { Component } from 'react';

import staticpages from '../config.static.js';

import Page from './Page';
import NotFound from './NotFound';

class StaticPages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pages: staticpages
    }
  }

  render() {
    let { match } = this.props;
    let pageId = match.params.pageId;
    // default to NotFound for pages that don't exist
    let componentToRender = <NotFound />;

    if(this.state.pages[pageId] !== undefined) {
      componentToRender = <Page
                            title={this.state.pages[pageId].title}
                            theme={this.state.pages[pageId].theme}
                            sections={this.state.pages[pageId].sections} />;
    }
    return componentToRender
  }
}

export default StaticPages;
