import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ContactInfo } from './Contact';

class Home extends Component {

  // Services sub menu
  // <h2>Services</h2>
  // <ul>
  //   <li><Link to="/community-programs">Community Programs</Link></li>
  //   <li><Link to="/school-initiatives">School Initiatives</Link></li>
  //   <li><Link to="/health-learning">Health Learning</Link></li>
  //   <li><Link to="/signin">Sign In</Link></li>
  //   <li><Link to="/search">Search</Link></li>
  // </ul>

  // Company sub menu
  // <h2>Company</h2>
  // <ul>
  //   <li><Link to="/about">About</Link></li>
  //   <li><Link to="/team">Team</Link></li>
  //   <li><Link to="/departments">Departments</Link></li>
  //   <li><Link to="/faq">FAQ</Link></li>
  //   <li><Link to="/press">Press</Link></li>
  //   <li><Link to="/careers">Careers</Link></li>
  //   <li><Link to="/research-facility">Research Facility</Link></li>
  //   <li><Link to="/campus">Campus</Link></li>
  //   <li><Link to="/partners">Partners</Link></li>
  //   <li><Link to="/support">Support</Link></li>
  // </ul>

  render() {
    return (
      <div>

        <div className="flex-row">
          <div className="flex-col half algae">
            <Link to="/health"><h3>Learn more about our health research and drug discoveries</h3></Link>
          </div>
          <div className="flex-col fish">
            <Link to="/">
              <img alt="" src="assets/images/logo/full-logo.png"/>
            </Link>
            <h1>Partners <span className="shrink">in</span> Community <span className="emphasis">Health</span></h1>
            <p>A leader in building intelligent and sustainable health solutions</p>
            <div className="button-container">
              <Link className="button-left" to="/health">&larr; Human Health</Link>
              <Link className="button-right" to="/food-systems">Food Systems &rarr;</Link>
            </div>
          </div>
          <div className="flex-col half lettuce">
            <Link to="/food-systems"><h3>Learn more about our innovative food and animal feed systems</h3></Link>
          </div>
        </div>

        <div className="flex-row">
          <div className="flex-col myera-team"><Link to="/team"></Link></div>
          <div className="flex-col our-story">
            <h2>Our Story</h2>
            <p>{"We are researchers, scientists, doctors, farmers and innovators. Our combined goal is to provide targeted nutrition through sustainable and intelligent food production systems for a more vibrant and healthy society. A suite of innovative technologies, tools and practices have been created that provide solutions to local communities' health and nutritional needs."}</p>
            <Link className="cta" to="/about">Learn More</Link>
          </div>
        </div>

        <div className="flex-row">
          <div className="flex-col leaf">
            <div className="vertical-nav-container">

            </div>
            <div className="vertical-nav-container">

            </div>
          </div>

          <div className="flex-col contact-card">
            <ContactInfo />
          </div>
        </div>

      </div>


    );
  }
}

export default Home;
