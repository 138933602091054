import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
// import { slide as MobileMenu } from 'react-burger-menu';

class Header extends Component {
  // showMenu (event) {
  //   event.preventDefault();
  // }

  // remove from menu temporarily
  // <li><NavLink to="/team">Team</NavLink></li>
  // <li><NavLink to="/departments">Departments</NavLink></li>
  // <li><NavLink to="/faq">FAQ</NavLink></li>
  // <li><NavLink to="/press">Press</NavLink></li>

  renderNav() {
    return (
      <ul className="nav">
        <li><NavLink to="/">Home</NavLink></li>
        <li><NavLink to="/about">About</NavLink></li>
        <li><NavLink to="/contact">Contact</NavLink></li>
      </ul>
    )
  }

  render() {
    return (
      <div className="header">
        <Link className="logo" to="/"></Link>
        { this.renderNav() }
        <ul className="tools">
          {/*<li><NavLink className="signin" to="/signin">Sign In</NavLink></li>*/}
          <li><Link to="#"><div className="hamburger-menu"></div></Link></li>
          {/*<li><Link to="/search"><div className="search"></div></Link></li>*/}
        </ul>
      </div>
    );
  }
}

export default Header;
