import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import UnderConstruction from './UnderConstruction'

class Search extends Component {

  render() {
    return (
      <UnderConstruction />
    );
  }
}

// Search.propTypes = {
// }

// Search.defaultProps = {
// }

export default Search;
