import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

class About extends Component {

  render() {
    return (
      <div className="page-wrapper">
        <div className="title-card">
          <h1>About Us</h1>
          <p>Myera is a leader in building intelligent and sustainable health solutions.</p>
          <HashLink smooth to="#goal"><div className="arrow-down-yellow"></div></HashLink>
        </div>
        <img alt="myera-team" className="about-cover-image" src="/assets/images/splash/team.jpg" />
        <section id="goal">
          <h2>Our Goal</h2>
          <p>
            To provide targeted nutrition through sustainable and intelligent food production systems
            for a more vibrant and healthy society. We have developed a suite of innovative technologies,
            tools and practices to provide solutions for local communities’ health and nutritional needs.
          </p>
        </section>
        <Link to="/health">
          <div className="cta left cta-red"><p>Learn more about our health research and drug discoveries</p></div>
        </Link>
        <Link to="/food-systems">
          <div className="cta right cta-green"><p>Learn more about our innovative food and animal feed systems</p></div>
        </Link>
        <div className="alt">
          <h2>How we got here</h2>
          <p>
            Founder Bruce Hardy harnessed his passion for developing focused, intelligent technological
            solutions for early and personalized health intervention to bridge the divide between sustainable
            food supplies and community nutritional needs. Myera Group was founded in <strong>2015</strong> and   
            has since grown and evolved into an internationally-recognized leader in building intelligent
            and sustainable health solutions.
          </p>
          <p>
            Bruce has leveraged his expertise and success in the field of applied technological research to
            foster unique partnerships that bring together leading- edge experts in nutrition, human health
            and food production. Driven by a commitment to improve the health of our most vulnerable
            communities, Myera is making a difference in the lives of children and adults through research,
            innovation and knowledge transfer.
          </p>
        </div>
      </div>
    );
  }
}

export default About;
