import React, { Component } from 'react';

import { Route, Switch } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Signin from './Signin';
import Search from './Search';
import Support from './Support';
import StaticPages from './StaticPages';
import Footer from './Footer';
import NotFound from './NotFound'

class App extends Component {

  render() {
    return (
        <div className="body-wrapper">
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/signin" component={Signin}/>
            <Route path="/search" component={Search}/>
            <Route path="/support" component={Support}/>
            {/* Wildcard pageId, try to load corresponding `page` from component state. This is for use prior to full CMS + API integration */}
            <Route path="/:pageId" component={StaticPages}/>
            {/* Redundant catchall below, because Pages will always catchall and return 404, but this for future proofing if we change routes */}
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </div>
    );
  }
}

export default App;
