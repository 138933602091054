import React, { Component } from 'react';

class UnderConstruction extends Component {
  render() {
    return (
      <div className="not-found-404">
        <h1>Under Construction</h1>
        <p>
          The page you requested is currently being worked on and should be available shortly.
        </p>
      </div>
    );
  }
}

export default UnderConstruction;
