import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';

/* This is the contact information card on homepage, as well as left column of contact page */
class ContactInfo extends Component {
  render() {
    return (
      <div className="contact">
        <img alt="" src="assets/images/logo/full-logo.png" width="300px"/>
        <div>
          <h2>Get in Touch</h2>
          <span>Email</span> <a href="mailto:info@myeragroup.ca">info@myeragroup.ca</a><br />
          <span>Phone</span> <a href="tel:12042955580">1.204.295.5580</a>
        </div>
        <div>
          <h2>Connect With Us</h2>
          <ul className="socials">
            <li><a href="https://www.facebook.com/myeragroup/" target="_blank" rel="noopener noreferrer"><div className="social-item facebook"></div></a></li>
            {/* <li><a href="#"><div className="social-item instagram" target="_blank" rel="noopener noreferrer"></div></a></li>
            <li><a href="#"><div className="social-item twitter" target="_blank" rel="noopener noreferrer"></div></a></li>
            <li><a href="#"><div className="social-item pinterest" target="_blank" rel="noopener noreferrer"></div></a></li> */}
          </ul>
        </div>
      </div>
    );
  }
}


const CustomMapMarker = ({ text, image }) => <div className="map-marker"><img alt="marker" height="64px" src={image} /></div>;

class ContactMap extends Component {

  render() {
    return (
      <div className="map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAfFWhg6P1z44eazB1OY2gxdzxBSbvGBRE" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <CustomMapMarker
            lat={this.props.center.lat}
            lng={this.props.center.lng}
            text={'26 Henlow Bay'}
            image={'assets/images/icons/map-marker@2x.png'}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

ContactMap.defaultProps = {
  center: {
    lat: 49.816121573703604,
    lng: -97.18348250258714
  },
  zoom: 17
}

/* This is the actual contact page */
class Contact extends Component {
  render() {
    return (
      <div className="flex-row">
        <ContactMap className="flex-col" />
        <ContactInfo className="flex-col" />
      </div>
    );
  }
}

export { ContactInfo };
export default Contact;
